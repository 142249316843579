<template>
  <div>
    <div class="columns is-gapless p-5">
      <div class="column is-6 is-mobile-12 is-hidden-mobile">
        <img src="@/assets/img/imgTextAndImageLeftSection.webp" class="mx-auto is-block">
      </div>
      <div class="column is-6 is-mobile-12">
        <h1 class="has-text-centered letterSpacingShort is-size-2-tablet is-size-3-mobile mt-5">
          <span class="fontWeightBlack letterSpacingLarge  has-text-dark">
            GARANTIZAMOS
          </span>
          <br>
          <span class="fontWeightBlack  has-text-dark">
            TU SEGURIDAD
          </span>
        </h1>
        <div class="is-block m-2 ">
          <p class="has-text-centered has-text-dark mb-5 mt-4 letterSpacingShort fontWeightRegular is-size-4 bgFontSections mx-auto p-2">
            ✔ Diseñamos tu casa.
          </p>
          <p class="has-text-centered has-text-dark mb-5 mt-4 letterSpacingShort fontWeightRegular is-size-4 bgFontSections mx-auto p-2">
            ✔ Realizamos el cálculo estructural.
          </p>
          <p class="has-text-centered has-text-dark mb-5 mt-4 letterSpacingShort fontWeightRegular is-size-4 bgFontSections mx-auto p-2">
            ✔ Mantenemos un costo controlado de obra.
          </p>
          <p class="has-text-centered has-text-dark mb-5 mt-4 letterSpacingShort fontWeightRegular is-size-4 bgFontSections mx-auto p-2">
            ✔ Aseguramos la calidad de tu casa.
          </p>
          <p class="has-text-centered has-text-dark mb-5 mt-4 letterSpacingShort fontWeightRegular is-size-4 bgFontSections mx-auto p-2">
            ✔ Brindamos una garantía por el trabajo.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
