<template>
  <div>
    <div class="overParticles lineFooter2 bgColorGrey is-block mx-auto mt-4" />
    <div class="columns pt-6 pl-2 pr-2">
      <div class="column is-8 has-text-black mx-auto is-block">
        <h1 class="has-text-centered colorSecondary is-size-2-tablet is-size-3-mobile mt-3">
          <span class="fontWeightRegular has-text-black">
            Contamos con Tecnología de última generación,
una máquina conformadora de Paneles Steel Frame
          </span>
        </h1>
      </div>
    </div><br>
     <div class="overParticles lineFooter2 bgColorGrey is-block mx-auto mt-4" />
     <br><br><br>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
