<template>
  <div>
    <div class="columns is-gapless p-5">
          
      <div class="column is-6-tablet is-mobile-12 centerBlock is-hidden-tablet">
        <font-awesome-icon class="mr-1 pb-05 colorPrimary" :icon="['fa', 'star']"  />
        <font-awesome-icon class="mr-1 pb-05 colorPrimary" :icon="['fa', 'star']"  />
        <font-awesome-icon class="mr-1 pb-05 colorPrimary" :icon="['fa', 'star']"  />
        <font-awesome-icon class="mr-1 pb-05 colorPrimary" :icon="['fa', 'star']"  />
        <font-awesome-icon class="mr-1 pb-05 colorPrimary" :icon="['fa', 'star']"  />
        <img
          :src="require(`@/assets/img/fotogenial.jpg`)"
          class="mx-auto is-block mt-2 width100"
          style="border-radius: 4px"
        >
      </div>
      <div class="column is-6 is-mobile-12 mt-5">
        <h1 class="has-text-centered letterSpacingShort is-size-2-tablet is-size-3-mobile">
          <span class="fontWeightBlack letterSpacingLarge has-text-black">
            {{ titleOne }}
          </span>
          <br>
          <span class="fontWeightBlack colorSecondary has-text-black">
            {{ titleTwo }}
          </span>
        </h1>
        <div class="is-block m-2 colorSecondary">
          <p class="has-text-centered has-text-black mb-5 mt-4 letterSpacingShort fontWeightRegular is-size-4 bgFontSections mx-auto p-2">
            ✔ {{ itemList1 }}
          </p>
          <p class="has-text-centered has-text-black mb-5 mt-4 letterSpacingShort fontWeightRegular is-size-4 bgFontSections mx-auto p-2">
            ✔ {{ itemList2 }}
          </p>
          <p class="has-text-centered has-text-black mb-5 mt-4 letterSpacingShort fontWeightRegular is-size-4 bgFontSections mx-auto p-2">
            ✔ {{ itemList3 }}
          </p>
          <p class="has-text-centered has-text-black mb-5 mt-4 letterSpacingShort fontWeightRegular is-size-4 bgFontSections mx-auto p-2">
            ✔ {{ itemList4 }}
          </p>
          <p class="has-text-centered has-text-black mb-5 mt-4 letterSpacingShort fontWeightRegular is-size-4 bgFontSections mx-auto p-2">
            ✔ {{ itemList5 }}
          </p>
        </div>
      </div>
      <div class="column is-6 is-mobile-12">
        <img
          :src="require(`@/assets/img/${img}.jpg`)"
          class="mx-auto is-block mt-7 width100"
        >
      </div>
    </div>
    
    <div class="columns is-gapless p-5">
      
    
      <div class="column is-6-tablet is-mobile-12 centerBlock is-hidden-mobile">
        <font-awesome-icon class="mr-1 pb-05 colorPrimary" :icon="['fa', 'star']"  />
        <font-awesome-icon class="mr-1 pb-05 colorPrimary" :icon="['fa', 'star']"  />
        <font-awesome-icon class="mr-1 pb-05 colorPrimary" :icon="['fa', 'star']"  />
        <font-awesome-icon class="mr-1 pb-05 colorPrimary" :icon="['fa', 'star']"  />
        <font-awesome-icon class="mr-1 pb-05 colorPrimary" :icon="['fa', 'star']"  />
        <img
          :src="require(`@/assets/img/fotogenial.jpg`)"
          class="mx-auto is-block mt-2 width100"
          style="border-radius: 4px"
        >
      </div>
          
      <div class="column is-6-tablet is-mobile-12 centerBlock is-hidden-tablet">
              <h1
        class="is-size-2-tablet is-size-3-mobile has-text-black  fontWeightBlack fontMoreBlack  pt-2 pl-2 pr-2 pb-0 mb-3 fontUpperCase">
      A Conoce todos los beneficios
      </h1>
      <h1
        class="is-size-2-tablet is-size-3-mobile has-text-black  fontWeightBlack fontMoreBlack  pt-2 pl-2 pr-2 pb-0 mb-3 fontUpperCase">
      Armado en solo dos días
      </h1>
      <h1
        class="is-size-2-tablet is-size-3-mobile has-text-black   fontMoreBlack  pt-2 pl-2 pr-2 pb-0 mb-3 ">
      Contactanos para recibir toda la información <span class="fontWeightBlack">¡Conseguila un 50% del valor del Steel Framing!</span>
      </h1>
      <h1
        class="is-size-2-tablet is-size-3-mobile has-text-black  fontMoreBlack  pt-2 pl-2 pr-2 pb-0 mb-3 fontUpperCase">
      Consultá ahora
      </h1>
      </div>

            <div class="column is-6-tablet is-mobile-12 centerBlock is-hidden-mobile">
                    <h1
        class="is-size-2-tablet is-size-3-mobile has-text-black  fontWeightBlack fontMoreBlack  pt-5 pl-6 pr-6 pb-0 mb-3 fontUpperCase mt-6">
      Conoce todos los beneficios
      </h1>
      <h1
        class="is-size-2-tablet is-size-3-mobile has-text-black  fontWeightBlack fontMoreBlack  pt-2 pl-6 pr-6 pb-0 mb-3 fontUpperCase">
      Armado en solo dos días
      </h1>
      <h1
        class="is-size-2-tablet is-size-3-mobile has-text-black   fontMoreBlack  pt-2 pl-6 pr-6 pb-0 mb-3 ">
      Contactanos para recibir toda la información <span class="fontWeightBlack">¡Conseguila un 50% del valor del Steel Framing!</span>
      </h1>
      <h1
        class="is-size-2-tablet is-size-3-mobile has-text-black  fontWeightBlack fontMoreBlack  pt-4 pl-6 pr-6 pb-0 mb-3 fontUpperCase">
      Consultá ahora
      </h1>
      </div>
          </div>
  </div>
</template>

<script>
export default {
  name: 'Separator',
  props: ['titleOne', 'titleTwo',
          'itemList1', 'itemList2', 'itemList3', 'itemList4', 'itemList5',
          'img']
}
</script>

<style>

</style>
