<template>
  <div class="home">
    <!-- Slider -->
    <Slider  id="inicio"/>
    <!-- Home -->
    <SeparatorSection
    :titleOne="'MÁS DE'" 
    :titleOneColor="'has-text-white fontWeightBlack'"
    :titleTwo="'38 CASAS'"
    :titleTwoColor="'colorPrimary fontWeightBlack'"
    :titleThree="'ENTREGADAS'"
    :titleThreeColor="'has-text-white fontWeightBlack'"
    :subtitleOne="'En La Plata, City Bell, Berisso y Gonnet | Construímos tu casa en 60 días'"
    :subtitleTwo="'Contactanos | Presupuestos en 48 HS'"
    :subtitleColor="'has-text-white'"
    :bgSeparator="'bgColorBlack p-6'"
    />
    <SeparatorImages class="is-hidden" />
<br>
    <vue-particles
    color="#f5d716"
    :particle-opacity="0.2"
    :particles-number="180"
    shape-type="triangle"
    :particle-size="10"
    :line-opacity="0"
    :lines-distance="10"
    :move-speed="3"
    class="particlesGeneral is-hidden"
    />
    <Services id="services"
    :title="'Construimos tu casa en seco'" 
    :propName1="'Diseño y planificación'"
    :propName2="'Ejecución de obra'"
    :propName3="'Llave en mano'"
    :propImg1="'pencil-ruler'"
    :propImg2="'hard-hat'"
    :propImg3="'home'"
    :classContainer="'mt-7'"
    />
    <SeparatorSection
    :titleOne="'NOS ESPECIALIZAMOS EN'" 
    :titleOneColor="'has-text-white fontWeightBlack'"
    :titleTwo="'AMPLIACIONES Y REMODELACIONES'"
    :titleTwoColor="'colorPrimary fontWeightBlack'"
    :titleThree="''"
    :titleThreeColor="'has-text-white fontWeightBlack'"
    :subtitleOne="'Ampliamos, remodelamos y refaccionamos Tu casa de Steel Framing y Construcción en Seco'"
    :subtitleTwo="'Contactanos | Presupuestos en 48 HS'"
    :subtitleColor="'has-text-white'"
    :bgSeparator="'bgColorBlack p-6'"
    />
    <img src="../assets/img/steel.jpg" class="width100 is-block">
    <div class="bgColorBlack paddingBlock">
      <h1
        class="titleBig2 has-text-white fontWeightBlack" style="z-index: 10;position:relative">
        ¿Qué es el Steel Framing?
      </h1>
      <div class="lineFooter2 bgColorGrey is-block mx-auto mt-4" />
      <p class="mt-5 mb-0 is-size-4-tablet is-size-5-mobile has-text-white has-text-centered">
        El Steel Framing es un sistema de construcción en seco formado por un entramado de perfiles de acero galvanizado.
      </p>
    </div>
    <RecomendationSection />
    <br> <br> <br>
    <SeparatorSection
    :titleOne="'Arquitecto y albañiles'" 
    :titleOneColor="'has-text-white fontWeightBlack fontUpperCase'"
    :titleTwo="'DE EXCELENCIA'"
    :titleTwoColor="'colorPrimary fontWeightBlack'"
    :titleThree="''"
    :titleThreeColor="'has-text-white fontWeightBlack'"
    :subtitleOne="'Experiencia y precio, nuestros presupuestos se encuentran un 10% debajo del preciode construcción promedio'"
    :subtitleTwo="''"
    :subtitleColor="'has-text-white'"
    :bgSeparator="'bgColorBlack paddingBlock'"
    />
      <div>
      <div class="columns pl-5 pr-5 bgColorPrimary paddingBlock" >
        <div class="column is-8 has-text-centered mx-auto is-block">
          <h1 class="has-text-centered letterSpacingShort is-size-2-tablet is-size-3-mobile lineHeight2">
            <span>
             Si estás interesado en Construir tu Casa con la mejor tecnología del mercado....
            </span>
          </h1>
          <div class="is-block m-2">
            <p
              class="has-text-centered mt-4 letterSpacingShort fontWeight is-size-4-tablet is-size-5-mobile"
            >
            <a :href="brand.whatsappUrl" target="_blank" class="bgColorBlack pt-3 pb-3 pl-2 pr-2" style="border-radius:4px">
              <img src="@/assets/img/whatsapp.webp" class="is-inline-block pr-1" style="width:40px;position:relative;bottom:-10px">
              <p class="fontWeightRegular has-text-white is-inline-block">
                Presupuestos
              </p>
            </a>
              <br>
            </p>
          </div>
        </div>
      </div>
    </div>
    <img src="../assets/img/steelframing.webp" class="width100 is-block">
 


          <div class="columns pl-5 pr-5 bgColorPrimary paddingBlock" >
        <div class="column is-8 has-text-centered mx-auto is-block">
          <h1 class="has-text-centered letterSpacingShort fontWeightBlack  has-text-dark is-size-2-tablet is-size-3-mobile lineHeight2">
            <span>
             
¿Por qué elegimos la construcción en seco con steel frame y no la construcción en madera o ladrillo?
            </span>
          </h1>
          <div class="is-block m-2">
            <p
              class="has-text-justified mt-4 letterSpacingShort  has-text-dark fontWeight is-size-4-tablet is-size-5-mobile"
            >
<br><br>
<span class="fontWeightBlack">CONSTRUCCIÓN CON MADERA:</span> En Argentina no hay buena calidad de madera, los tratamientos no son buenos y con el tiempo aparecen problemas como las termitas, esto implica un gasto de mantenimiento muy grande. Sin el mantenimiento adecuado, la estructura de la casa está en riesgo.
<br><br>
<span class="fontWeightBlack">CONSTRUCCIÓN CON LADRILLO:</span> ¿Quién no tuvo problemas de humedad? ¿Quién no tuvo problemas de rajadura? ¿Quién no tuvo que romper una pared para encontrar una pérdida de agua? Y podemos seguir por horas.
Y sumado a esto el gran gasto energético que tenemos al intentar calefaccionar una casa de ladrillos.
              <br>
            </p>
          </div>
        </div>
      </div>
      
    <SeparatorSection
    :titleOne="'LOS MEJORES'" 
    :titleOneColor="'has-text-white fontWeightBlack'"
    :titleTwo="'AMPLIANDO Y REFACCIONANDO'"
    :titleTwoColor="'colorPrimary fontWeightBlack'"
    :titleThree="''"
    :titleThreeColor="'has-text-white fontWeightBlack'"
    :subtitleOne="'Nos especializamos en refacciones de Steel Framing y Construcción en Seco'"
    :subtitleTwo="'Contactanos | Presupuestos en 48 HS'"
    :subtitleColor="'has-text-white'"
    :bgSeparator="'bgColorBlack p-6'"
    />
      <div class="columns pt-6" >
        <div class="column is-8 mx-auto is-block">
          <h1
            class="is-size-1-tablet is-size-3-mobile  has-text-black pl-6 pr-6 pb-0 mb-3">
          Conocé en <span class="fontWeightBold">detalle <br>como se compone nuestro muro</span> 
          </h1>
        </div>
        </div>

      <div class="columns pl-5 pr-5" >
        <div class="column is-8 mx-auto is-block">
      <div class="columns pl-5 pr-5" >
        <div class="column is-6 has-text-centered mx-auto is-block">
          <img src="../assets/img/muro.jpg" class="width100">
        </div>
        <div class="column is-6 has-text-left mx-auto is-block">
      01- Placa de roca yeso de 12,5 mm.<br>
      02- Nylon de 100 micrones, barrera de vapor.<br>
      03- Perfil de acero galvanizado 100 mm.<br>
      04- Placa estructural OSB de 9,5 mm.<br>
      05- Membrana Hidrófuga.<br>
      06- Poliestireno expandido de 20 kg.<br>
      07- Malla de fibra de vidrio, refuerzo revoque.<br>
      08- Base Coat de cemento y polímeros.<br>
      09- Base cuarzo para revestimiento plástico.<br>
      10- Revestimiento plástico impermeable.<br>

      11- Anclaje de perfil reforzado, pintado en epoxi.<br>
      12- Solera galvanizada 100 mm.<br>
      13- Piso interior.<br>
      14- Hormigón platea H21.<br>
      15- Malla electrosoldada 6 mm.<br>
      16- Encadenado perimetral de 20 x 20 cm.<br>
      17- Nylon 200 micrones barrera humedad.<br>
      18- Piso exterior.<br>
      19- Terreno tosca compactada 20 cm.<br>
      20-Varilla roscada 12 mm.
        </div>
      </div>
            </div>
                  </div><br><br><br>

    <Services id="services"
    :title="'SERVICIOS'" 
    :propName1="'Construción desde 0'"
    :propImg1="'ruler-combined'"
    :propName2="'Refacciones'"
    :propImg2="'house-user'"
    :propName3="'Llave en mano'"
    :propImg3="'key'"
    :propName4="'Cambio de techos'"
    :propImg4="'tools'"
    :propName5="'Baños y cocinas'"
    :propImg5="'toilet'"
    :propName6="'Reparación de goteras'"
    :propImg6="'tint'"   
    :classContainer="'mt-0 mb-6 pb-4'"
    />
    <RecomendationSection2 />
    <SeparatorSection
      :titleOne="'DESDE HACE 10 AÑOS'" 
      :titleOneColor="'has-text-white fontWeightBlack fontMoreBlack'"
      :subtitleOne="'Brindamos la mejor experiencia constructiva de la ciudad.'"
      :subtitleColor="'has-text-white'"
      :bgSeparator="'bgColorBlack paddingBlock'"
    />   
        <div class="container-fluid bgPattern pb-6">
      <h1
        class="is-size-1-tablet is-size-3-mobile colorPrimary fontWeightBlack fontMoreBlack  pt-6 pl-6 pr-6 pb-3 fontUpperCase">
        OBRAS Y EXPERIENCIA
      </h1>
      <NumberCounter class="is-inline-block"/>
      <NumberCounterTwo class="is-inline-block ml-2"/> 
    </div>

          <TextAndImageLeft />
        <TextAndImageRight 
      :titleOne="'DESPREOCUPATE'"
      :titleTwo="'DEL PROCESO DE OBRA'"
      :itemList1="'Diseñamos y ejecutamos.'"
      :itemList2="'Planificamos mano de obra.'"
      :itemList3="'Compramos los productos.'"
      :itemList4="'Dirigimos la construcción de tu casa.'"
      :itemList5="'Entregamos llave en mano.'"
      :img="'imgTextAndImageRightSection'"
      />

<div class="is-hidden">
      <h1
        class="is-size-1-tablet is-size-3-mobile fontWeightBlack fontMoreBlack  has-text-black pt-6 pl-6 pr-6 pb-0 mb-3 fontUpperCase">
       <span class="is-size-1-mobile">CASA </span> PREFABRICADA
      </h1>
      <div style="max-width:500px; display: block; margin: auto;">
     <h1
        class="is-size-3 fontWeightBlack bgColorPrimary fontMoreBlack has-text-black  pt-2 pl-6 pr-6 pb-3 fontUpperCase">
        SUPER ECONÓMICA
      </h1>
      </div>

      <TextAndImageRight2 
      :titleOne="'DESPREOCUPATE'"
      :titleTwo="'DEL PROCESO DE OBRA'"
      :itemList1="'Construcción de platea.'"
      :itemList2="'Vivienda nivelada.'"
      :itemList3="'Con Aberturas y techo.'"
      :itemList4="'Lista para el comprador.'"
      :itemList5="'Entregamos llave en mano.'"
      :img="'prefa'"
      />
</div><br><br><br>

                      <SeparatorSection
      :titleOne="'CONTRATANOS AHORA'" 
      :titleOneColor="'has-text-white fontWeightBlack fontMoreBlack'"
      :subtitleOne="'Construí tu casa con los mejores materiales del mercado.'"
      :subtitleColor="'has-text-white'"
      :bgSeparator="'bgColorBlack paddingBlock'"
    />  
    <SeparatorImagesTwo />
    <div class="container-fluid hasShadow2 pt-1 pb-2" id="us">
      <div class="container pl-6 pr-6">
          <h1
            class="titleBig2 colorPrimary  pt-6 mb-2 fontPrimary fontWeightBlack fontMoreBlack fontUpperCase">
              Quienes somos
          </h1>
          <p
            class="is-size-4 mb-6 textAlignResponsiveCenter">
            egle Construcciones | Somos una empresa dedicada a la construcción en seco con más de 10 años de experiencia en La Plata y alrededores, hemos realizado casas en toda la zona por lo que podemos brindar referencias comprobables ¡Contactanos ahora! El presupuesto y la visita es sin cargo para brindar la mejor atención.
          </p>
      </div>  
    </div>

    <!-- Footer -->
    <Footer id="contact" />
    <Whatsapp />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Slider from '@/components/sections/Slider'
import SeparatorSection from '@/components/sections/SeparatorSection'
import SeparatorImages from '@/components/sections/SeparatorImages'
import SeparatorImagesTwo from '@/components/sections/SeparatorImagesTwo'
import RecomendationSection from '@/components/sections/RecomendationSection'
import RecomendationSection2 from '@/components/sections/RecomendationSection2'
import Services from '@/components/sections/Services'
import TextAndImageRight from '@/components/sections/TextAndImageRight'
import TextAndImageRight2 from '@/components/sections/TextAndImageRight2'
import TextAndImageLeft from '@/components/sections/TextAndImageLeft'
import NumberCounter from '@/components/sections/NumberCounterJs'
import NumberCounterTwo from '@/components/sections/NumberCounterJsTwo'
import Footer from '@/components/Footer'
import Whatsapp from '@/components/Whatsapp'
export default {
  name: 'Home',
  components: {
    Slider ,
    SeparatorSection,
    SeparatorImages,
    SeparatorImagesTwo,
    RecomendationSection,
    RecomendationSection2,
    Services,
    TextAndImageRight,
    TextAndImageRight2,
    TextAndImageLeft,
    NumberCounter,
    NumberCounterTwo,
    Footer,
    Whatsapp
  },
  data () {
    return {
      animateImg: 'none'
    }
  },
  computed: {
    ...mapState('brand', ['brand'])
  },
  methods: {
    animateWhatsappImg () {
      this.animateImg = 'rotateImgOnHover'
      setTimeout(function(){
        this.animateImg = 'none'
      }.bind(this), 1000)
    }
  }
}
</script>
