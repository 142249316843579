<template>
  <section>
    <div>          
      <div class="columns is-multiline pb-0 mb-0 is-gapless">
        <div class="column is-4 p-0">
            <img
                src="@/assets/img/trabajos/1.jpg"
                class="width100 is-block"
                style="height:100%"
            >
        </div>
        <div class="column is-4 p-0">
            <img
                src="@/assets/img/trabajos/2.jpg"
                class="width100 is-block"
                style="height:100%"
            >
        </div>
        <div class="column is-4 p-0">
            <img
                src="@/assets/img/trabajos/3.jpg"
                class="width100 is-block"
                style="height:100%"
            >
        </div>
        <div class="column is-4 p-0">
            <img
                src="@/assets/img/trabajos/4.jpg"
                class="width100 is-block"
                style="height:100%"
            >
        </div>
        <div class="column is-4 p-0">
            <img
                src="@/assets/img/trabajos/6.jpg"
                class="width100 is-block"
                style="height:100%"
            >
        </div>
        <div class="column is-4 p-0">
            <img
                src="@/assets/img/trabajos/5.jpg"
                class="width100 is-block"
                style="height:100%"
            >
        </div>
      </div>
      <div class="columns is-multiline is-gapless">
        <div
          v-for="(item, index) in images"
          :key="index"
          class="column is-4 is-12-mobile"
        >
          <b-carousel
            class=""
            :indicator="false"
            :pause-info="false"
          >
            <b-carousel-item>
              <section :class="`hero ${item.bg} is-bold`" style="height:600px">
                <div class="hero-body has-text-centered">
                  <div>
                    <h2 class="has-text-white is-size-2 fontWeightBold mt-7">
                      {{ item.title }}
                    </h2>
                    <h2 :class="`has-text-white is-size-4 fontWeightRegular letterSpacingShort | p-5`">
                      {{ item.subtitle }}
                    </h2>
                  </div>
                </div>
              </section>
            </b-carousel-item>
          </b-carousel>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Slider',
  data () {
    return {
        progress: true,
        progressType: 'is-primary',
        images: [
            {
            title: '',
            subtitle: '',
            bg: 'bgFistSectionB1'
            },
            {
            title: '',
            subtitle: '',
            bg: 'bgFistSectionB2'
            },
            {
            title: '',
            subtitle: '',
            bg: 'bgFistSectionB3'
            }
        ]
    }
  }
}
</script>

<style lang="sass">

.bgFistSectionB1
    width: 100%
    min-height:400px
    background-size: cover
    background-image: url("../../assets/img/obras/1.jpg")

.bgFistSectionB2
    width:100%
    min-height:400px
    background-size: cover
    background-image: url("../../assets/img/obras/2.jpg")

.bgFistSectionB3
    width:100%
    min-height:400px
    background-size: cover
    background-image: url("../../assets/img/obras/3.jpg")

.bgFistSectionB4
    width:100%
    min-height:400px
    background-size: cover
    background-image: url("../../assets/img/obras/4.jpg")
    
.bgFistSectionB5
    width:100%
    min-height:400px
    background-size: cover
    background-image: url("../../assets/img/obras/5.jpg")
    
.bgFistSectionB6
    width:100%
    min-height:400px
    background-size: cover
    background-image: url("../../assets/img/obras/6.jpg")
</style>
