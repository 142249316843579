<template>
  <div class="container-fluid paddingServiceSection pt-2" :class="classContainer">
    <div class="container">
      <h1
        class="overParticles titleBig2 has-text-dark fontWeightBold ">
        {{title}}
      </h1>
      <div class="overParticles lineFooter2 bgColorGrey is-block mx-auto mt-4" />
      <div class="overParticles columns is-gapless p-5 is-multiline">
        <div class="column is-mobile-12 mt-6" :class="`is-${service.number}`" v-for="(service, index) in services" :key="index">
            <font-awesome-icon :icon="service.img" class="overParticles colorPrimary services bgColorPrimaryLight has-text-dark" />
            <p class="overParticles fontWeightBlack mt-5 mb-0 fontUpperCase is-size-4-tablet is-size-5-mobile">
              {{ service.name }}
            </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['title', 'propImg1', 'propName1', 'propImg2', 'propName2', 'propImg3', 'propName3',
  'propName4', 'propImg4', 'propName5', 'propImg5', 'propName6', 'propImg6', 'classContainer'],
  data() {
    return {
      services: [
       {
        img: this.propImg1,
        name: this.propName1,
        number: 4
       },
       {
        img: this.propImg2,
        name: this.propName2,
        number: 4
       },
       {
        img: this.propImg3,
        name: this.propName3,
        number: 4
       },
       {
        img: this.propImg4,
        name: this.propName4,
        number: 4
       },
       {
        img: this.propImg5,
        name: this.propName5,
        number: 4
       },
       {
        img: this.propImg6,
        name: this.propName6,
        number: 4
       }          
      ]
    }
  }
}
</script>

<style>

</style>
