<template>
  <div id="app">
    <div class="hasShadow3 width100">
        <img
            src="@/assets/img/logoNegro.png"
            :alt="brand.name"
            class="logo p-3"
        >
    </div>
    <div class="is-block width100 positionFixed zIndex999 is-hidden">
        <div id="logoMenuFold" class="columns bgColorPrimary mb-0">
            <div class="column is-12 mb-0 pb-0">
                <img
                    src="@/assets/img/logoNegro.png"
                    :alt="brand.name"
                    class="logoMobile is-hidden-tablet pb-2 pt-3"
                    id="imgMenuLogoMobileFold"
                >
                <img
                    src="@/assets/img/logoNegro.png"
                    :alt="brand.name"
                    class="logo is-hidden-mobile p-3"
                    id="imgMenuLogoFold"
                >
            </div>
        </div>
        <b-navbar
        class=""
        centered
        id="menuFold" 
        :shadow="true">
            <template #start class="has-text-center">
                <b-navbar-item href="#inicio" class="sizeMenuFont fontUpperCase has-text-black">
                    <font-awesome-icon class="mr-1 pb-05" :icon="['fa', 'home']"  />  Inicio
                </b-navbar-item>
                <b-navbar-item href="#services" class="sizeMenuFont fontUpperCase has-text-black">
                    <font-awesome-icon class="mr-1 pb-05" :icon="['fa', 'landmark']"  />  Servicios
                </b-navbar-item>
                <b-navbar-item href="#us" class="sizeMenuFont fontUpperCase has-text-black">
                    <font-awesome-icon class="mr-1 pb-05" :icon="['fa', 'user']"  />  Quienes somos
                </b-navbar-item>
                <b-navbar-item href="#contact" class="sizeMenuFont fontUpperCase has-text-black">
                    <font-awesome-icon class="mr-1 pb-05" :icon="['fa', 'envelope']"  />  Contacto
                </b-navbar-item>
            </template>
        </b-navbar>
    </div>
    <router-view/>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    metaInfo() {
        return {
            title: "egle Construcciones"
        }
    },
    computed: {
        ...mapState('brand', ['brand'])
    }
}
// // JS Vainilla
// Menu animation
function menuFold() {
    document.body.scrollTop > 10 || document.documentElement.scrollTop > 0
        ? (
            (
                document.getElementById("logoMenuFold").className = "columns bgColorPrimary mb-0 fondoPropio fadeMenuOff",
                document.getElementById("imgMenuLogoFold").className = "is-hidden-mobile p-3 logoMenu",
                document.getElementById("imgMenuLogoMobileFold").className = "logoMobile is-hidden-tablet pb-2 pt-3 scale-out-center"
            )
        )
        : (
            (
                document.getElementById("logoMenuFold").className = "columns bgColorPrimary mb-0 fondoPropio fadeMenu",
                document.getElementById("imgMenuLogoFold").className = "is-hidden-mobile p-3 logoMenuOut",
                document.getElementById("imgMenuLogoMobileFold").className = "logoMobile is-hidden-tablet pb-2 pt-3 scale-in-center"
            )
        );
}
window.onscroll = function () {
    menuFold()
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
