<template>
  <div class="counter fontWeightBlack">
    <p class="counterTitle has-text-white">Refacciones</p>
    <ICountUp
      class="has-text-white fontWeightBlack fontMoreBlack"
      :delay="delay"
      :endVal="endVal"
      :options="options"
      @ready="onReady"
    />
  </div>
</template>

<script type="text/babel">
  import ICountUp from 'vue-countup-v2';
  export default {
    name: 'demo',
    components: {
      ICountUp
    },
    data() {
      return {
        delay: 500,
        endVal: 97,
        options: {
          useEasing: true,
          useGrouping: true,
          separator: ',',
          decimal: '.',
          prefix: '',
          suffix: '',
          duration: 68
        },
        
      };
    },
    methods: {
      onReady: function(instance, CountUp) {
        const that = this;
        instance.update(that.endVal + 120);
        console.log(CountUp)
      }
    }
  };
</script>
