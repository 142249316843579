<template>
  <div>
    <div class="columns pt-6 pl-2 pr-2">
      <div class="column is-12 has-text-black">
        <h1 class="has-text-centered colorSecondary  is-size-2-tablet is-size-3-mobile">
          <span class="fontWeightRegular has-text-black">¿Qué</span> 
          <span class="fontWeightBlack has-text-black p-3">Beneficios</span>
        </h1>
        <h1 class="has-text-centered colorSecondary is-size-2-tablet is-size-3-mobile mt-3">
          <span class="fontWeightRegular has-text-black">
            tiene la construcción en seco?
          </span>
        </h1>
      </div>
    </div>
    
    <div class="columns is-centered has-text-centered pl-5 pr-5 pt-0">
      <div class="column is-8 is-mobile-12 pt-0 mt-0 pb-0 mb-0">
          <p class="pl-5 has-text-black has-text-left mb-3 mt-4 letterSpacingShort fontWeightBold is-size-4 bgColorPrimary maxWidth300 p-2">
           <font-awesome-icon icon="volume-mute" class="has-text-dark is-inline-block subtitle1 positionI is-size-3" />
           Aislación acústica
          </p>
          <p class="has-text-black mb-5 mt-0 letterSpacingShort fontWeightRegular is-size-4 p-2 has-text-left">
            Mayor aislación sonora que la construcción tradicional. Disminuye el ruido proveniente del exterior y entre los mismos ambientes.
          </p>
      </div>
    </div>
    
    <div class="columns is-centered has-text-centered pl-5 pr-5 pt-3">
      <div class="column is-8 is-mobile-12 pt-0 mt-0 pb-0 mb-0">
          <p class="pl-5 has-text-black has-text-left mb-3 mt-4 letterSpacingShort fontWeightBold is-size-4 bgColorPrimary maxWidth300 p-2">
           <font-awesome-icon icon="sun" class="has-text-dark is-inline-block subtitle1 positionI is-size-3" />
           Aislación térmica
          </p>
          <p class="has-text-black mb-5 mt-0 letterSpacingShort fontWeightRegular is-size-4 p-2 has-text-left">
            Mejora notablemente el comportamiento térmico interior, haciendo imperceptible la temperatura del exterior.
          </p>
      </div>
    </div>
    
    <div class="columns is-centered has-text-centered pl-5 pr-5 pt-3">
      <div class="column is-8 is-mobile-12 pt-0 mt-0 pb-0 mb-0">
          <p class="pl-5 has-text-black has-text-left mb-3 mt-4 letterSpacingShort fontWeightBold is-size-4 bgColorPrimary maxWidth300 p-2">
           <font-awesome-icon icon="lightbulb" class="has-text-dark is-inline-block subtitle1 positionI is-size-3" />
            Ahorro energético
          </p>
          <p class="has-text-black mb-5 mt-0 letterSpacingShort fontWeightRegular is-size-4 p-2 has-text-left">
            Disminuye los gastos de energía de calefacción y aire acondicionado, contribuyendo al cuidado del medio ambiente.
          </p>
      </div>
    </div>
    
    <div class="columns is-centered has-text-centered pl-5 pr-5 pt-3">
      <div class="column is-8 is-mobile-12 pt-0 mt-0 pb-0 mb-0">
          <p class="pl-5 has-text-black has-text-left mb-3 mt-4 letterSpacingShort fontWeightBold is-size-4 bgColorPrimary maxWidth300 p-2">
           <font-awesome-icon icon="tools" class="has-text-dark is-inline-block subtitle1 positionI is-size-3" />
            Rapidez de armado
          </p>
          <p class="has-text-black mb-5 mt-0 letterSpacingShort fontWeightRegular is-size-4 p-2 has-text-left">
            Reducción del tiempo de obra entre un 30% y 60%.
          </p>
      </div>
    </div>
        
    <div class="columns is-centered has-text-centered pl-5 pr-5 pt-3">
      <div class="column is-8 is-mobile-12 pt-0 mt-0 pb-0 mb-0">
          <p class="pl-5 has-text-black has-text-left mb-3 mt-4 letterSpacingShort fontWeightBold is-size-4 bgColorPrimary maxWidth300 p-2">
           <font-awesome-icon icon="home" class="has-text-dark is-inline-block subtitle1 positionI is-size-3" />
            Mayor solidez
          </p>
          <p class="has-text-black mb-5 mt-0 letterSpacingShort fontWeightRegular is-size-4 p-2 has-text-left">
            Los perfiles de Acero Galvanizado proporcionan una estructura sólida que soportan diferentes factores climáticos.
          </p>
      </div>
    </div>
        
    <div class="columns is-centered has-text-centered pl-5 pr-5 pt-3">
      <div class="column is-8 is-mobile-12 pt-0 mt-0 pb-0 mb-0">
          <p class="pl-5 has-text-black has-text-left mb-3 mt-4 letterSpacingShort fontWeightBold is-size-4 bgColorPrimary maxWidth300 p-2">
           <font-awesome-icon icon="clock" class="has-text-dark is-inline-block subtitle1 positionI is-size-3" />
            Alta durabilidad
          </p>
          <p class="has-text-black mb-5 mt-0 letterSpacingShort fontWeightRegular is-size-4 p-2 has-text-left">
            Los perfiles de Acero Galvanizado tienen una durabilidad de 300 años.
          </p>
      </div>
    </div>

  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
