<template>
  <div>
    <div class="columns is-gapless p-5">
      <div class="column is-6 is-mobile-12 mt-5">
        <h1 class="has-text-centered letterSpacingShort is-size-2-tablet is-size-3-mobile">
          <span class="fontWeightBlack letterSpacingLarge has-text-black">
            {{ titleOne }}
          </span>
          <br>
          <span class="fontWeightBlack colorSecondary has-text-black">
            {{ titleTwo }}
          </span>
        </h1>
        <div class="is-block m-2 colorSecondary">
          <p class="has-text-centered has-text-black mb-5 mt-4 letterSpacingShort fontWeightRegular is-size-4 bgFontSections mx-auto p-2">
            ✔ {{ itemList1 }}
          </p>
          <p class="has-text-centered has-text-black mb-5 mt-4 letterSpacingShort fontWeightRegular is-size-4 bgFontSections mx-auto p-2">
            ✔ {{ itemList2 }}
          </p>
          <p class="has-text-centered has-text-black mb-5 mt-4 letterSpacingShort fontWeightRegular is-size-4 bgFontSections mx-auto p-2">
            ✔ {{ itemList3 }}
          </p>
          <p class="has-text-centered has-text-black mb-5 mt-4 letterSpacingShort fontWeightRegular is-size-4 bgFontSections mx-auto p-2">
            ✔ {{ itemList4 }}
          </p>
          <p class="has-text-centered has-text-black mb-5 mt-4 letterSpacingShort fontWeightRegular is-size-4 bgFontSections mx-auto p-2">
            ✔ {{ itemList5 }}
          </p>
        </div>
      </div>
      <div class="column is-6 is-mobile-12">
        <img
          :src="require(`@/assets/img/${img}.webp`)"
          class="mx-auto is-block mt-7"
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Separator',
  props: ['titleOne', 'titleTwo',
          'itemList1', 'itemList2', 'itemList3', 'itemList4', 'itemList5',
          'img']
}
</script>

<style>

</style>
