<template>
  <section>
    <b-carousel :indicator="false" :pause-info="false">
      <b-carousel-item >
        <section :class="`hero sliderHeightPadding bg-img1 is-bold`">
            <div class="hero-body container">
              <div>
                <h2 class="titleBig fontNoSoBlackFine has-text-white has-text-centered scale-in-center fontPrimary fontWeightBlack">
                   TU CASA EN SECO
                </h2>
                <h2 class="has-text-white is-size-2 fontWeightBold letterSpacingShort">
                  {{ param.text }}
                </h2>
                <h2 class="titleBig3 fontItalic has-text-white has-text-centered slide-in-left">
                   <span > Con llave en mano </span>
                </h2>
                <h2 class="mt-2 mb-2 is-size-2-tablet is-size-5-mobile fontItalic has-text-white has-text-centered fade-in">
                  Presupuesto y visita <br class="is-hidden-tablet"> sin cargo
                </h2>
                <div class="mt-5">
                  <button
                  class="is-inline-block zIndex999 buttonSliderWhatsapp pr-0 is-hidden"
                  onclick="window.location.href='https://api.whatsapp.com/send?phone=542213088411'">
                    <img src="@/assets/img/botonWhatsapp.jpg"
                      class="buttonMenuWhatsapp is-inline-block fade-in"
                      > 
                  </button>
                </div>
              </div>
            </div>
        </section>
      </b-carousel-item>
    </b-carousel>
  </section>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: 'Slider',
    data () {
      return {
          param: '',
          progress: true,
          progressType: 'is-primary',
          carousels: [
              {
              background: 'img1'
              },
              {
              background: 'img2'
              }
          ]
      }
    },
    created () {
      this.catchParams()
      console.log(this.$route.query)
    },
    computed: {
      ...mapState('brand', ['brand'])
    },
    methods: {
      catchParams () {
        console.log('Aca', this.$route.query)
        this.param = this.$route.query
    }
  }
}
</script>

<style lang="sass">

.bg-img1
    width: 100%
    background-size: cover
    background-repeat: no-repeat
    @media (min-width: 720px)
        background-image: url("../../assets/img/slider1.jpg")
    @media (max-width: 720px)
        background-image: url("../../assets/img/sliderMobil1.jpg")

</style>
