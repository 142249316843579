<template>
  <section>
    <div>
      <div class="columns is-multiline is-gapless">
        <div
          v-for="(item, index) in images"
          :key="index"
          class="column is-4 is-12-mobile"
        >
          <b-carousel
            class="marginTop"
            :indicator="false"
            :pause-info="false"
          >
            <b-carousel-item>
              <section :class="`hero ${item.bg} is-bold`">
                <div class="hero-body has-text-centered minHeighSeparator">
                  <div>
                    <h2 class="has-text-white is-size-2 fontWeightBlack fontMoreBlack fontUpperCase paddingSeparatorImages">
                      {{ item.title }}
                    </h2>
                    <h2 :class="`has-text-white is-size-3 fontWeightRegular letterSpacingShort fontUpperCase | p-5`">
                      {{ item.subtitle }}
                    </h2>
                  </div>
                </div>
              </section>
            </b-carousel-item>
          </b-carousel>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Slider',
  data () {
    return {
        progress: true,
        progressType: 'is-primary',
        images: [
            {
            title: '✔ Para toda la vida',
            subtitle: 'Relajate sabiendo que es para siempre',
            bg: 'bgFistSection2'
            },
            {
            title: '✔ Alta resistencia',
            subtitle: 'Sustentabilidad con el paso del tiempo',
            bg: 'bgFistSection1'
            },
            {
            title: '✔ Gran aislamiento',
            subtitle: 'Fresca en verano y calida en invierno',
            bg: 'bgFistSection3'
            }
        ]
    }
  }
}
</script>

<style lang="sass">

.bgFistSection1
    width: 100%
    min-height:400px
    background-size: cover
    background-image: url("../../assets/img/separatorImg1.jpg")

.bgFistSection2
    width:100%
    min-height:400px
    background-size: cover
    background-image: url("../../assets/img/separatorImg2.jpg")

.bgFistSection3
    width:100%
    min-height:400px
    background-size: cover
    background-image: url("../../assets/img/separatorImg3.jpg")

</style>
