<!-- eslint-disable -->
<template>
  <footer>
    <div class="container-fluid">
      <div class="container pl-6 pr-6">
        <div class="row">
          <div class="columns is-multiline pt-6 pb-6">
            <div class="column is-12-mobile is-12-tablet is-6-desktop is-6-widescreen is-6-fullhd">
              <p class="title is-2 fontWeightBlack fontMoreBlack">Solicitá <br> un presupuesto Ahora</p>
                  <button
                  class="is-inline-block zIndex999 buttonSliderWhatsapp"
                  onclick="window.location.href='https://api.whatsapp.com/send?phone=542213088411'">
                    <img src="@/assets/img/botonWhatsapp.jpg"
                      class="buttonMenuWhatsapp is-inline-block fade-in"
                      > 
                  </button>
            </div> 
            <div class="column is-12-mobile is-12-tablet is-6-desktop is-6-widescreen is-6-fullhd pr-0 paddingFooterEspecial">
              <p class="title is-2 textAlignResponsive fontWeightBlack">
                Teléfono
              </p>
                  <div class="textAlignResponsive">
                        <font-awesome-icon icon="mobile-alt" class="colorPrimary is-inline-block subtitle1" />
                        <p class="is-inline-block is-size-3 ml-2 fontWeightBlack" >
                          {{ brand.phone }}
                        </p>
                        <br>
                        <font-awesome-icon icon="map-marker-alt" class="colorPrimary is-inline-block subtitle1" />
                        <p class="is-inline-block is-size-3 ml-2 fontWeightBlack" >
                          La Plata y alrededores
                        </p>
                      <br>
                  </div>
            </div>           
          </div>
        </div>
      </div>
    </div>
    <div class="bgColorBlack ">
        <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d52343.07030732944!2d-57.9881896491909!3d-34.9204528992912!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95a2e62b1f0085a1%3A0xbcfc44f0547312e3!2sLa%20Plata%2C%20Provincia%20de%20Buenos%20Aires!5e0!3m2!1ses-419!2sar!4v1611427138698!5m2!1ses-419!2sar"
        width="100%"
        height="450"
        frameborder="0"
        style="border:0;"
        allowfullscreen=""
        aria-hidden="false"
        tabindex="0"></iframe>
        <p class="has-text-centered subtitle is-5 has-text-white mb-2 mt-6">
          {{ brand.slogan }}
        </p>
        <p class="has-text-centered subtitle is-5 has-text-white mb-2">
          {{ brand.name }}
        </p>
        <br><br>

    </div>
  </footer>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Footer',
  computed: {
    ...mapState('brand', ['brand'])
  }
}
</script>
