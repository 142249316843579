export default {
    namespaced: true,
    state: {

        //Marca
        brand:{
            name:'egle Construcciones',
            slogan:'CONSTRUIMOS TU CASA PARA TODA LA VIDA',
            email: 'info@egleconstrucciones.com.ar', // Para el servidor usar de email usar el password: 3610410543aA$q 
            emailUrl: 'egleconstrucciones.com.ar',
            phone: '+54 221 3088-411',

            urlWebForImgEmail: 'https://egleconstrucciones.com.ar',
            whatsappUrl: 'https://wa.me/542213088411',
            urlIG:'',
            urlFB:''
        },

    },

    mutations: {

    },

    actions: {

    },
    getters:{
        
    }
}